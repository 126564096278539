@charset "utf-8";

/* skip Navi */
#accessibility {
  position: absolute;
  top: -1px;
  left: 0;
  background-color: #333;
  z-index: 999999;
}
#accessibility a {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 25px;
  margin-top: -100px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background: #000;
}
#accessibility a:link {
  color: #333;
}
#accessibility a:focus {
  margin-top: 0;
  text-decoration: underline;
  color: #fff;
}

.pc {
  display: block;
}
.m {
  display: none;
}

@media screen and (max-width: 900px) {
  .pc {
    display: none;
  }
  .m {
    display: block;
  }
  .m_w100 {
    width: 100%;
  }
}

/* header */
#header {
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 1001;
  top: 0;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
}
#header .logo {
  position: absolute;
  top: 15px;
  left: 30px;
  z-index: 300;
}
#header .logo a {
  display: block;
  font-size: 0;
  width: 141px;
  height: 53px;
  background: url("../img/common/logo.png") no-repeat 0 0;
}
.open-menu #header .logo {
  z-index: 10;
}

#gnb {
  position: relative;
  width: 100%;
  padding: 0;
  height: 90px;
  z-index: 200;
}
#gnb .menu {
  padding-top: 25px;
  text-align: center;
  height: 90px;
  position: relative;
  z-index: 99;
  background: #fff;
}
#gnb .menu:after {
  content: "";
  display: block;
  clear: both;
}
#gnb .menu > li {
  display: inline-block;
  height: 100%;
  vertical-align: top;
}
#gnb .menu > li > a {
  position: relative;
  display: inline-block;
  padding: 10px 31px;
  line-height: 1.2;
  font-size: 17px;
  color: #333;
  letter-spacing: -1px;
  font-weight: 400;
}
#gnb .menu > li > a:hover {
  text-decoration: none;
  color: #0094fe;
}

#gnb .util {
  position: absolute;
  right: 30px;
  top: 20px;
  width: 390px;
  z-index: 100;
  text-align: right;
}
#gnb .util li {
  display: inline-block;
  vertical-align: top;
  margin-left: 17px;
}
#gnb .util li a {
  vertical-align: top;
}
#gnb .util li a:hover {
  text-decoration: none;
}
#gnb .util .youtube {
  padding: 16px 0 0;
  margin-left: 0;
}
#gnb .util .youtube a {
  display: inline-block;
  width: 28px;
  height: 23px;
  background: url("../img/common/btn_youtube.png") no-repeat 0 0;
  font-size: 0;
}
#gnb .util .youtube a:hover {
  background: url("../img/common/btn_youtube_on.png") no-repeat 0 0;
  transition: all 0.3s ease 0.03s;
}
#gnb .util .blog {
  padding: 16px 0 0;
}
#gnb .util .blog a {
  display: inline-block;
  width: 27px;
  height: 23px;
  background: url("../img/common/btn_blog.png") no-repeat 0 0;
  font-size: 0;
}
#gnb .util .blog a:hover {
  background: url("../img/common/btn_blog_on.png") no-repeat 0 0;
  transition: all 0.3s ease 0.03s;
}
#gnb .util .gitlab {
  padding: 13px 0 0;
}
#gnb .util .gitlab a {
  display: inline-block;
  width: 32px;
  height: 29px;
  background: url("../img/common/btn_gitlab.png") no-repeat 0 0;
  font-size: 0;
}
#gnb .util .gitlab a:hover {
  background: url("../img/common/btn_gitlab_on.png") no-repeat 0 0;
  transition: all 0.3s ease 0.03s;
}
#gnb .util .shop {
  padding: 13px 0 0;
  margin-left: 12px;
}
#gnb .util .shop a {
  display: inline-block;
  width: 68px;
  height: 24px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #b2b2b2;
  text-align: center;
  border: 2px solid #b2b2b2;
  border-radius: 12px;
}
#gnb .util .shop a:hover {
  color: #0897fe;
  border: 2px solid #0897fe;
  transition: all 0.3s ease 0.03s;
}
#gnb .util .member {
  margin-left: 14px;
  position: relative;
  padding: 11px 0 0;
}
#gnb .util .member:before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 12px;
  background: #d8d8d8;
  margin-right: 33px;
  vertical-align: -4px;
}
#gnb .util .member a:after {
  content: "";
  display: block;
  width: 48px;
  height: 50px;
  background: url("../img/common/bg_member.png") no-repeat 0 0;
  position: absolute;
  left: 15px;
  top: 0;
}
#gnb .util .member a {
  display: inline-block;
  width: 92px;
  height: 30px;
  background: #a5a5a5;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 0 0 0 28px;
  line-height: 32px;
  text-align: left;
  border-radius: 14px;
}
#gnb .util .member a:hover:after {
  background: url("../img/common/bg_member_on.png") no-repeat 0 0;
  transition: all 0.3s ease 0.03s;
}
#gnb .util .member a:hover {
  background: #ffe8b8;
  color: #9e7b31;
}

#open_mn {
  display: none;
  position: absolute;
  right: 66px;
  top: 29px;
  width: 35px;
  height: 35px;
  vertical-align: top;
  z-index: 201;
}
#open_mn a {
  display: block;
  width: 35px;
  height: 35px;
  background: url("../img/common/btn_menu.png") no-repeat 50% 50%;
  font-size: 0;
}
#close_mn {
  position: absolute;
  right: 20px;
  top: 12px;
  width: 35px;
  height: 35px;
  vertical-align: top;
  z-index: 200;
}
#close_mn a {
  display: block;
  width: 35px;
  height: 35px;
  background: #fff url("../img/common/btn_close2.png") no-repeat 50% 50%;
  font-size: 0;
}

@media screen and (max-width: 1375px) {
  #gnb .menu {
    margin-left: -115px;
  }
}
@media screen and (max-width: 1290px) {
  #gnb .menu > li > a {
    padding: 13px 17px 0;
  }
}
@media screen and (max-width: 1279px) {
  #header {
    width: 100%;
    left: 0;
    margin: 0;
    position: fixed;
    height: 61px;
    background: #fff;
  }
  #header .logo {
    left: 18px;
    top: 8px;
  }
  #header .logo a {
    height: 40px;
    background-size: auto 100%;
  }
  #header .mobile {
    display: block;
    font-size: 0;
    opacity: 1;
    /* background: url("../img/common/btn_mo_menu.png") no-repeat 50% 50%; */
    position: absolute;
    right: 113px;
    top: 0;
    width: 50px;
    height: 50px;
    background-size: 18px auto;
  }
  .open-menu #header .mobile {
    transition: all 0.5s ease 0.3s;
    opacity: 0;
  }
  #header .mask {
    opacity: 0;
  }
  .open-menu #header .mask {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    opacity: 0.8;
    transition: all 0.5s ease 0.3s;
  }

  #open_mn {
    right: 20px;
    top: 14px;
  }
  .open-menu #close_mn {
    transition: all 0.5s ease 0.3s;
    opacity: 1;
    z-index: 202;
  }
  .open-menu #open_mn {
    transition: all 0.5s ease 0.3s;
    opacity: 0;
  }

  #gnb::after {
    display: none;
    transition: none;
  }
  #gnb {
    overflow-y: auto;
    position: fixed;
    top: 60px;
    left: auto;
    right: -1300px;
    width: 100%;
    height: calc(100% - 60px);
    z-index: 1000;
    background: #fff;
    text-align: right;
  }
  #gnb .util {
    position: relative;
    top: auto;
    right: auto;
    padding: 12px 10px 15px;
    height: auto;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
    width: 100%;
    border-top: 1px solid #e9e9e9;
  }
  #gnb .util li {
    margin-left: 7px;
  }
  #gnb .util .shop {
    margin-left: 7px;
  }
  #gnb .util .member {
    margin-left: 10px;
    position: relative;
    padding: 11px 0 0;
  }
  #gnb .util .member:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 12px;
    background: #d8d8d8;
    margin-right: 33px;
    vertical-align: -4px;
  }
  #gnb .util .member a:after {
    content: "";
    display: block;
    width: 48px;
    height: 50px;
    background: url("../img/common/bg_member.png") no-repeat 0 0;
    position: absolute;
    left: 13px;
    top: 0;
  }

  #gnb .menu {
    padding-top: 27px;
    margin-left: 0;
    width: 100%;
    box-sizing: border-box;
    height: auto;
  }
  #gnb .menu > li {
    display: block;
    width: 100%;
  }
  #gnb .menu > li > a {
    padding: 20px 0;
  }
  #gnb .menu > li.on > a:after {
    display: none;
  }

  .open-menu #gnb .menu {
    height: calc(100% - 58px);
    padding-bottom: 50px;
  }
  .open-menu #gnb .menu li {
    height: auto;
  }
  #open_mn {
    display: block;
  }
}
@media screen and (max-width: 700px) {
  #gnb {
    top: 60px;
    height: calc(100% - 60px);
  }
}
@media screen and (max-width: 439px) {
  #gnb {
    width: 100%;
  }
  #gnb #close_mn {
    right: 300px;
    height: 20px;
    width: 20px;
  }
  #gnb #close_mn a {
    background-size: 90% auto;
  }
}
@media screen and (max-width: 320px) {
  #gnb #close_mn {
    right: 295px;
  }
}

/* footer */
#footer {
  position: relative;
  width: 100%;
  background: #fff;
  border-top: 1px solid #e0e0e0;
  font-family: "Nanum Gothic";
  height: 278px;
}
#footer .btn_top {
  width: 42px;
  height: 45px;
  position: absolute;
  left: 50%;
  margin-left: -21px;
  top: -26px;
  z-index: 100;
}
#footer .btn_top a {
  width: 42px;
  height: 45px;
  display: block;
  background: url("../img/common/btn_top.png") no-repeat 0 0;
  font-size: 0;
}
#footer .footer_in {
  position: relative;
  width: 1280px;
  margin: 0 auto;
}
#footer h2 {
  padding: 60px 0 18px 180px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Nanum Gothic";
  color: #000;
}
#footer .logo {
  position: absolute;
  left: 0;
  top: 64px;
  width: 120px;
  height: 110px;
  font-size: 0;
  background: url("../img/common/logo_footer.png") no-repeat 0 0;
}
#footer dl {
  padding: 0 0 8px 180px;
  display: block;
  overflow: hidden;
  font-family: "Nanum Gothic";
}
#footer .info_txt1 dt {
  float: left;
  width: 94px;
  color: #717d8d;
  font-size: 14px;
}
#footer .info_txt1 dd {
  float: left;
  width: auto;
  color: #010101;
  font-size: 14px;
}
#footer .info_txt1 dd span {
  color: #43a1ee;
  font-weight: 400;
}
#footer .info_txt2 dt {
  float: left;
  width: 50px;
  color: #717d8d;
  font-size: 14px;
  margin-left: 70px;
}
#footer .info_txt2 dt:first-child {
  float: left;
  width: 94px;
  color: #717d8d;
  font-size: 14px;
  margin-left: 0;
}
#footer .info_txt2 dd {
  float: left;
  width: auto;
  color: #010101;
  font-size: 14px;
}
#footer .ft_link {
  position: absolute;
  right: 0;
  top: 62px;
  width: 222px;
  z-index: 100;
  text-align: right;
}
#footer .ft_link li {
  display: inline-block;
  width: auto;
  padding: 0 0 0 30px;
}
#footer .ft_link li a {
  display: inline-block;
  width: auto;
  font-size: 14px;
  color: #000;
  line-height: 1.4;
}
#footer .ft_link li:first-child a {
  color: #717d8d;
}
#footer .copy {
  padding: 6px 0 0 180px;
  font-size: 14px;
  line-height: 1.6;
  color: #717d8d;
}
#section7 .btn_top {
  display: none;
}
.btn_top.pro {
  display: block;
}
#section7 .btn_top.pro {
  display: block;
}
@media screen and (max-width: 1279px) {
  #footer .footer_in {
    width: 100%;
  }
  #footer .logo {
    left: 20px;
  }
  #footer .ft_link {
    right: 5%;
  }
  #footer h2 {
    padding: 60px 5% 18px 200px;
  }
  #footer dl {
    padding: 0 5% 8px 200px;
  }
  #footer .copy {
    padding: 6px 0 0 200px;
  }
  #section7 .btn_top {
    display: block;
  }
  .btn_top.pro {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  #footer .logo {
    display: none;
  }
  #footer h2 {
    padding: 60px 0 18px 5%;
  }
  #footer dl {
    padding: 0 0 8px 5%;
  }
  #footer .copy {
    padding: 6px 0 0 5%;
  }
  #footer .info_txt1 dt {
    float: none;
    width: 100%;
  }
  #footer .info_txt1 dd {
    float: none;
    width: 100%;
    padding-bottom: 5px;
  }
  #footer .info_txt2 dt {
    float: left;
    width: 70px;
    margin: 0;
    padding-bottom: 5px;
  }
  #footer .info_txt2 dt:first-child {
    width: 70px;
  }
  #footer .info_txt2 dd {
    float: right;
    width: calc(100% - 70px);
    padding-bottom: 5px;
  }
}

/* contents */
.container {
  padding: 90px 0 0;
}
.hide_pc {
  display: block;
}
.hide_mo {
  display: none;
}
@media screen and (max-width: 1279px) {
  .container {
    padding: 61px 0 0;
  }
  .hide_mo {
    display: block;
  }
}
@media screen and (max-width: 800px) {
  .hide_pc {
    display: none;
  }
}

/* fullpage */
html.fp-enabled,
.fp-enabled body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
#superContainer {
  height: 100%;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
}
.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
  height: auto !important;
}
@media all and (max-width: 1279px) {
  .fp-section,
  .fp-tableCell {
    height: auto !important;
  }
}

/* section */
#section0 {
  position: relative;
  z-index: 2;
  background: url("../img/contents/bg_product_01.jpg") no-repeat 50% 50%;
  background-size: cover;
}
#section1 {
  position: relative;
  z-index: 2;
}
#section2 {
  position: relative;
  z-index: 2;
  background: #f6faff;
}
#section3 {
  position: relative;
  z-index: 2;
}
#section4 {
  position: relative;
  z-index: 2;
  background: #f6faff;
}
#section5 {
  position: relative;
  z-index: 2;
}
#section6 {
  position: relative;
  z-index: 2;
  background: #f6faff;
}
#section7 {
  position: relative;
  z-index: 2;
}

.farmosis_box {
  position: relative;
  padding: 65px 0 0;
  height: 100%;
}
.farmosis_box h2 {
  padding: 70px 0 0;
  font-size: 36px;
  font-family: "roboto";
  color: #fff;
  text-align: center;
}
.farmosis_box h2 span {
  width: auto;
  display: inline-block;
  position: relative;
  color: #fff;
  font-weight: bold;
  z-index: 1;
  padding: 0 12px;
  letter-spacing: -0.06em;
}
.farmosis_box h2 span:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(8, 151, 254, 0.2);
  height: 23px;
  z-index: -1;
}
.farmosis_box .txt1 {
  color: #fff;
  font-size: 32px;
  line-height: 1.3;
  padding: 31px 0 0;
  text-align: center;
  letter-spacing: -0.06em;
  word-spacing: -4px;
  font-weight: 300;
}
.farmosis_box .txt2 {
  color: #fff;
  font-size: 20px;
  line-height: 2.1;
  padding: 120px 0 0;
  text-align: center;
  letter-spacing: -0.06em;
  word-spacing: -4px;
}
.farmosis_box ul {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 234px;
  width: 1280px;
  margin-left: -640px;
  overflow: hidden;
}
.farmosis_box ul:before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #fff;
  opacity: 0.2;
}
.farmosis_box ul li {
  float: left;
  text-align: center;
  width: 17%;
  padding: 85px 0 0;
}
.farmosis_box ul li:nth-child(1),
.farmosis_box ul li:nth-child(6) {
  width: 11%;
}
.farmosis_box ul li:nth-child(2),
.farmosis_box ul li:nth-child(5) {
  width: 22%;
}
.farmosis_box ul li a {
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.06em;
}
.farmosis_box ul li span {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.06em;
  opacity: 0.5;
  margin: 2px 0 0;
  padding: 0 0 22px;
  background: url("../img/common/arrow_02.png") no-repeat 50% 100%;
}
.farmosis_box ul li a:hover {
  color: #0897fe;
}
.farmosis_box ul li a:hover span {
  background: url("../img/common/arrow_02.png") no-repeat 50% 100%;
  color: #4eb5ff;
}
.farmosis_box ul li:nth-child(1) span {
  font-size: 14px;
}
@media screen and (max-width: 1279px) {
  .farmosis_box {
    height: 600px;
    padding: 40px 0 0;
  }
  .farmosis_box h2 {
    padding: 60px 0 0;
    font-size: 28px;
  }
  .farmosis_box h2 span {
    padding: 0 8px;
  }
  .farmosis_box h2 span:before {
    height: 18px;
  }
  .farmosis_box .txt1 {
    font-size: 21px;
    padding: 20px 0 0;
  }
  .farmosis_box .txt2 {
    font-size: 16px;
    line-height: 1.8;
    padding: 60px 0 0;
  }
  .farmosis_box ul {
    left: 0;
    height: auto;
    width: 100%;
    margin-left: 0;
    padding: 0 0 20px;
  }
  .farmosis_box ul li {
    padding: 20px 0 0;
    width: 25%;
  }
  .farmosis_box ul li:nth-child(1),
  .farmosis_box ul li:nth-child(6) {
    width: 25%;
  }
  .farmosis_box ul li:nth-child(2),
  .farmosis_box ul li:nth-child(5) {
    width: 25%;
  }
  .farmosis_box ul li a {
    font-size: 17px;
  }
  .farmosis_box ul li span {
    font-size: 13px;
    font-weight: 300;
  }
}
@media screen and (max-width: 800px) {
  .farmosis_box {
    height: auto;
  }
  .farmosis_box .txt2 {
    padding: 60px 5% 0;
  }
  .farmosis_box ul {
    position: relative;
    left: auto;
    bottom: auto;
    margin: 50px 0 0;
  }
  .farmosis_box ul li {
    padding: 20px 0 0;
    width: 50%;
    vertical-align: top;
    height: 85px;
  }
  .farmosis_box ul li:nth-child(1),
  .farmosis_box ul li:nth-child(3),
  .farmosis_box ul li:nth-child(5) {
    width: 48%;
    float: left;
  }
  .farmosis_box ul li:nth-child(2),
  .farmosis_box ul li:nth-child(4),
  .farmosis_box ul li:nth-child(6) {
    width: 48%;
    float: right;
  }
}

.product_in {
  width: 1280px;
  margin: 0 auto;
  position: relative;
  padding: 65px 0 0;
  height: 100%;
}
.product_in:after {
  display: block;
  content: "";
  clear: both;
}
.product_in h2 {
  padding: 10% 0 0;
}
.product_in h2 span {
  width: auto;
  display: inline-block;
  position: relative;
  color: #000;
  font-size: 36px;
  font-weight: 500;
  z-index: 1;
  padding: 0 12px;
  letter-spacing: -0.06em;
  word-spacing: -6px;
}
.product_in h2 span:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 148, 254, 0.1);
  height: 23px;
  z-index: -1;
}
.product_in .txt1 {
  color: #000;
  font-size: 32px;
  line-height: 1.5;
  padding: 75px 0 0;
  letter-spacing: -0.06em;
  word-spacing: -4px;
  font-weight: 300;
}
.product_in .txt2 {
  color: #666;
  font-size: 20px;
  line-height: 1.5;
  padding: 35px 0 0;
  letter-spacing: -0.06em;
  word-spacing: -4px;
  font-weight: 300;
}
.product_in h3 {
  font-size: 20px;
  color: #0094fe;
  font-weight: 500;
  letter-spacing: -0.06em;
  line-height: 1.3;
}
.product_in .anchor {
  position: absolute;
  top: -90px;
  left: 0;
  width: 0;
  height: 0;
}
@media screen and (max-width: 1279px) {
  .product_in {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 0;
    height: auto;
  }
  .product_in h2 {
    padding: 60px 0 0;
  }
  .product_in h2 span {
    font-size: 28px;
    padding: 0 8px;
  }
  .product_in h2 span:before {
    height: 18px;
  }
  .product_in .txt1 {
    font-size: 21px;
    padding: 50px 0 0;
  }
  .product_in .txt2 {
    font-size: 16px;
    line-height: 1.8;
    padding: 20px 0 0;
  }
  .product_in .anchor {
    top: -61px;
  }
  .product_in h3 {
    font-size: 18px;
  }
}

.product_box1 {
  width: 100%;
  padding: 0 0 125px;
  position: relative;
}
.product_box1 h3 {
  margin: 55px 0 0;
}
.product_box1 ul {
  margin: 15px 0 0;
  position: relative;
}
.product_box1 ul:after {
  display: block;
  content: "";
  clear: both;
}
.product_box1 li {
  float: left;
  padding-right: 9px;
}
.product_box1 li .t {
  display: inline-block;
  padding: 0 15px;
  height: 32px;
  line-height: 32px;
  font-size: 15px;
  color: #000;
  font-weight: 500;
  border-radius: 15px;
  background: #eaeaea;
}
.product_box1 li .link {
  position: absolute;
  left: 0;
  top: 41px;
  display: none;
  left: 0;
  width: auto;
  height: 32px;
  line-height: 32px;
  font-size: 15px;
  color: #0094fe;
  font-weight: 300;
  border-radius: 15px;
  background: #eef6ff;
  padding: 0 20px;
  border: 1px solid #0897fe;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  letter-spacing: -0.05em;
}
.product_box1 li.on .t {
  background: #0897fe;
  color: #fff;
}
.product_box1 li.on .link {
  display: block;
}
.product_box1 li.on .link:after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 32px;
  background: url("../img/common/bg_link.png") no-repeat 100% 48%;
  vertical-align: top;
}
.product_box1 li:nth-child(1) .link {
  left: -30px;
}
.product_box1 li:nth-child(2) .link {
  left: 25px;
}
.motion_p1 {
  position: absolute;
  right: 10px;
  top: 63%;
  opacity: 0;
}
.active .motion_p1 {
  transition: all 0.75s ease 0.5s;
  top: 43%;
  opacity: 1;
}
@media screen and (max-width: 1279px) {
  .motion_p1 {
    position: absolute;
    right: 5%;
    top: 80px;
    width: 50%;
    opacity: 1;
  }
  .motion_p1 img {
    width: 100%;
  }
  .product_box1 li {
    height: 83px;
  }
}
@media screen and (max-width: 800px) {
  .motion_p1 {
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
    margin: 30px 0 0;
  }
  .product_box1 {
    padding: 0 0 20px;
  }
  .product_box1 li:nth-child(1) .link {
    left: 0;
  }
  .product_box1 li:nth-child(2) .link {
    left: 0;
  }
  .product_box1 li .t {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
  .product_box1 li .link {
    font-size: 12px;
    border-radius: 15px;
    background: #eef6ff;
    padding: 0 8px;
    height: 30px;
    line-height: 30px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.16);
    letter-spacing: -0.06em;
    word-spacing: -2px;
  }
}
@media screen and (max-width: 600px) {
  .product_box1 li {
    height: 105px;
  }
  .product_box1 li .link {
    padding: 6px 8px;
    height: auto;
    line-height: 1.4;
  }
  .product_box1 {
    padding: 0 0 0;
  }
}

.product_box2 {
  width: 100%;
  padding: 0 0 85px;
  position: relative;
}
.product_box2 .box {
  padding: 90px 0 0;
  overflow: hidden;
}
.product_box2 .box h3 {
  margin: 0 0 15px;
}
.product_box2 .box p {
  font-size: 16px;
  color: #000;
  font-weight: 300;
  letter-spacing: -0.06em;
  margin: 0 0 18px;
  line-height: 1.625;
}
.product_box2 .box_left {
  float: left;
  width: 298px;
}
.product_box2 .box_right {
  float: left;
  width: 250px;
}
.motion_p2 {
  position: absolute;
  right: 57px;
  top: 60%;
  opacity: 0;
}
.active .motion_p2 {
  transition: all 0.75s ease 0.5s;
  top: 43%;
  opacity: 1;
}
@media screen and (max-width: 1279px) {
  .motion_p2 {
    position: absolute;
    right: 5%;
    top: 80px;
    width: 40%;
    opacity: 1;
  }
  .motion_p2 img {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .motion_p2 {
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
    margin: 30px 0 0;
    text-align: center;
  }
  .motion_p2 img {
    width: 80%;
  }
  .product_box2 {
    padding: 0 0 30px;
  }
  .product_box2 .box {
    padding: 50px 0 0;
  }
  .product_box2 .box_left {
    float: none;
    width: 100%;
  }
  .product_box2 .box_right {
    float: none;
    width: 100%;
    margin: 30px 0 0;
  }
}

.product_box3 {
  width: 100%;
  padding: 0 0 0;
  position: relative;
  height: 100%;
}
.product_box3 h3 {
  margin: 60px 0 20px;
}
.product_box3 .t {
  width: 70px;
  height: 24px;
  line-height: 24px;
  margin-right: 3px;
  border-radius: 11px;
  background: #0094fe;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
}
.product_box3 p {
  font-size: 16px;
  color: #000;
  font-weight: 300;
  letter-spacing: -0.06em;
  word-spacing: -3px;
  margin: 0 0 7px;
  line-height: 1.3;
}
.product_box3 .txt {
  margin: 0;
  padding: 13px 0 60px;
}
.motion_p3 {
  position: absolute;
  right: 0;
  bottom: 20%;
  vertical-align: top;
  opacity: 0;
}
.active .motion_p3 {
  transition: all 0.75s ease 0.5s;
  bottom: 0;
  opacity: 1;
}
.motion_p3 img {
  vertical-align: top;
  margin: 0 -4px;
}
@media screen and (max-width: 1279px) {
  .product_box3 {
    height: auto;
  }
  .motion_p3 {
    position: absolute;
    right: 0;
    width: 50%;
    opacity: 1;
  }
  .motion_p3 img {
    width: 50%;
    margin: 0;
  }
}
@media screen and (max-width: 800px) {
  .motion_p3 {
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
    margin: 30px 0 0;
    text-align: center;
  }
}

.product_box4 {
  width: 100%;
  padding: 0 0 135px;
  position: relative;
}
.product_box4 .txt2 {
  padding-top: 110px;
}
.product_box4 ul {
  position: absolute;
  right: 0;
  top: 20%;
  width: 1px;
  height: 1px;
}
.product_box4 li {
  position: absolute;
  background: #fff;
  width: 300px;
  height: 280px;
  padding: 65px 36px 45px;
}
.product_box4 li:nth-child(1) {
  right: 406px;
  top: 184px;
}
.product_box4 li:nth-child(2) {
  right: 48px;
  top: 66px;
  padding: 58px 36px 45px;
}
.product_box4 li:nth-child(3) {
  right: 48px;
  top: 398px;
  padding: 58px 36px 45px;
}
.product_box4 h3 {
  margin: 0 0 16px;
}
.product_box4 p {
  font-size: 18px;
  color: #000;
  font-weight: 300;
  letter-spacing: -0.06em;
  word-spacing: -3px;
  line-height: 1.66;
}
.motion_p4 {
  position: absolute;
  right: 12px;
  top: -35px;
  opacity: 0;
  transition: all 0.75s ease 0.1s;
}
.motion_p5 {
  position: absolute;
  right: 8px;
  top: -33px;
  opacity: 0;
  transition: all 0.75s ease 0.1s;
}
.motion_p6 {
  position: absolute;
  right: -20px;
  top: -25px;
  opacity: 0;
  transition: all 0.75s ease 0.1s;
}
.active .motion_p4 {
  opacity: 1;
  transition: all 0.75s ease 0.5s;
}
.active .motion_p5 {
  opacity: 1;
  transition: all 0.75s ease 1s;
}
.active .motion_p6 {
  opacity: 1;
  transition: all 0.75s ease 1.5s;
}
@media screen and (max-width: 1279px) {
  .product_box4 {
    padding: 0 0 60px;
  }
  .product_box4 ul {
    top: 0;
  }
  .product_box4 li {
    width: 250px;
    height: auto;
    padding: 30px;
  }
  .product_box4 li:nth-child(1) {
    right: 320px;
    top: 184px;
  }
  .product_box4 li:nth-child(2) {
    right: 48px;
    top: 66px;
    padding: 30px;
  }
  .product_box4 li:nth-child(3) {
    right: 48px;
    top: 360px;
    padding: 30px;
  }
  .product_box4 li img {
    width: 120px;
  }
  .product_box4 h3 {
    font-size: 18px;
  }
  .product_box4 p {
    font-size: 15px;
    line-height: 1.66;
  }
  .motion_p4 {
    right: 12px;
    top: -35px;
    opacity: 1;
  }
  .motion_p5 {
    right: 8px;
    top: -33px;
    opacity: 1;
  }
  .motion_p6 {
    right: -20px;
    top: -45px;
    opacity: 1;
  }
}
@media screen and (max-width: 980px) {
  .product_box4 ul {
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
    height: auto;
  }
  .product_box4 li {
    position: relative;
    width: 100%;
    height: auto;
    padding: 30px;
    margin: 40px 0 0;
  }
  .product_box4 li:nth-child(1) {
    right: auto;
    top: auto;
  }
  .product_box4 li:nth-child(2) {
    right: auto;
    top: auto;
    padding: 30px;
  }
  .product_box4 li:nth-child(3) {
    right: auto;
    top: auto;
    padding: 30px;
  }
  .product_box4 li img {
    width: 100px;
  }
}
@media screen and (max-width: 800px) {
  .product_box4 h2 {
    padding: 60px 0 0;
  }
  .product_box4 .txt2 {
    padding-top: 50px;
  }
  .motion_p6 {
    right: 0;
    top: -35px;
    opacity: 1;
  }
}

.product_box5 {
  width: 100%;
  padding: 0 0 253px;
  position: relative;
}
.motion_p7 {
  position: absolute;
  right: -10px;
  top: 40%;
  opacity: 0;
}
.active .motion_p7 {
  transition: all 0.75s ease 0.5s;
  right: 20px;
  opacity: 1;
}
.motion_p8 {
  position: absolute;
  right: 210px;
  top: 95%;
  opacity: 0;
}
.active .motion_p8 {
  transition: all 0.75s ease 0.5s;
  right: 180px;
  opacity: 1;
}
@media screen and (max-width: 1279px) {
  .motion_p7 {
    right: 20px;
    top: 58px;
    width: 40%;
    opacity: 1;
  }
  .motion_p7 img {
    width: 100%;
  }
  .motion_p8 {
    right: 170px;
    top: 300px;
    width: 40%;
    opacity: 1;
  }
  .motion_p8 img {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .motion {
    overflow: hidden;
    margin: 30px 0 0;
  }
  .motion_p7 {
    position: relative;
    right: auto;
    top: auto;
    float: left;
    width: 50%;
    opacity: 1;
  }
  .active .motion_p7 {
    position: relative;
    right: auto;
    top: auto;
    float: left;
    width: 50%;
    opacity: 1;
  }
  .motion_p8 {
    position: relative;
    right: auto;
    top: auto;
    float: left;
    width: 50%;
    opacity: 1;
  }
  .active .motion_p8 {
    position: relative;
    right: auto;
    top: auto;
    float: left;
    width: 50%;
    opacity: 1;
  }
  .product_box5 {
    padding: 0 0 50px;
  }
}

.product_box6 {
  width: 100%;
  padding: 0 0 78px;
  position: relative;
}
.product_box6 ul {
  overflow: hidden;
  padding: 120px 0 0;
}
.product_box6 li {
  float: left;
  width: 462px;
}
.product_box6 li:nth-child(3) {
  width: 340px;
}
.product_box6 p {
  font-size: 18px;
  color: #000;
  font-weight: 300;
  letter-spacing: -0.06em;
  word-spacing: -3px;
  line-height: 1.66;
  padding: 17px 0 0;
}
.motion_p9 {
  position: absolute;
  right: 80px;
  top: 22%;
  opacity: 0;
}
.active .motion_p9 {
  transition: all 0.75s ease 0.5s;
  opacity: 1;
}
@media screen and (max-width: 1279px) {
  .product_box6 {
    padding: 0 0 50px;
  }
  .motion_p9 {
    right: 5%;
    width: 40%;
    opacity: 1;
  }
  .motion_p9 img {
    width: 100%;
  }
  .product_box6 ul {
    padding: 20px 0 0;
  }
  .product_box6 li {
    padding: 30px 0 0;
  }
}
@media screen and (max-width: 800px) {
  .motion_p9 {
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
  }
  .product_box6 ul {
    padding: 0 0 0;
  }
}

.product_box7 {
  width: 100%;
  padding: 43px 0 58px;
  position: relative;
  background: #43a1ee;
}
.product_box7 h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 42px;
}
.product_box7 .box {
  overflow: hidden;
  width: 1280px;
  margin: 0 auto;
  text-align: center;
}
.product_box7 .box p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 24px;
  word-spacing: -3px;
}
.product_box7 .box a {
  display: inline-block;
  width: 350px;
  height: 68px;
  line-height: 64px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 32px;
}
.product_box7 .box a:hover {
  background: #fff;
  color: #43a1ee;
  transition: all 0.3s ease 0.03s;
}
.product_box7 .box .box_left {
  float: left;
  width: 50%;
}
.product_box7 .box .box_right {
  float: right;
  width: 50%;
}
@media screen and (max-width: 1279px) {
  .product_box7 .box {
    width: 100%;
  }
  .product_box7 h2 {
    font-size: 20px;
    padding-bottom: 30px;
  }
  .product_box7 .box p {
    font-size: 15px;
  }
  .product_box7 .box a {
    width: 250px;
    height: 45px;
    line-height: 41px;
    font-size: 15px;
    border-radius: 25px;
  }
}
@media screen and (max-width: 800px) {
  .product_box7 .box .box_left {
    float: none;
    width: 100%;
    padding-bottom: 30px;
  }
  .product_box7 .box .box_right {
    float: none;
    width: 100%;
  }
  .product_box7 .box p {
    padding-bottom: 15px;
  }
}

.commpany_in {
  width: 1280px;
  margin: 0 auto;
  position: relative;
}
.commpany_in:after {
  display: block;
  content: "";
  clear: both;
}
.commpany_in h2 {
  padding: 72px 0 0;
  text-align: center;
}
.commpany_in h2 span {
  width: auto;
  display: inline-block;
  position: relative;
  color: #000;
  font-size: 36px;
  font-weight: 500;
  z-index: 1;
  padding: 0 12px;
  letter-spacing: -0.06em;
  word-spacing: -6px;
}
.commpany_in h2 span:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 148, 254, 0.1);
  height: 23px;
  z-index: -1;
}
.commpany_in .txt1 {
  color: #000;
  font-size: 32px;
  line-height: 1.5;
  padding: 30px 0 0;
  letter-spacing: -0.06em;
  word-spacing: -4px;
  font-weight: 300;
  text-align: center;
}
.commpany_in .txt2 {
  color: #666;
  font-size: 18px;
  line-height: 1.5;
  padding: 7px 0 0;
  letter-spacing: -0.06em;
  word-spacing: -4px;
  font-weight: 300;
  text-align: center;
}
.commpany_in .txt3 {
  color: #666;
  font-size: 18px;
  line-height: 1.5;
  padding: 7px 0 0;
  letter-spacing: -0.06em;
  word-spacing: -4px;
  font-weight: 300;
  text-align: center;
}
.commpany_in h3 {
  font-size: 20px;
  color: #0094fe;
  font-weight: 500;
  letter-spacing: -0.06em;
  line-height: 1.3;
}
@media screen and (max-width: 1279px) {
  .commpany_in {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .commpany_in h2 {
    padding: 60px 0 0;
  }
  .commpany_in h2 span {
    font-size: 28px;
    padding: 0 8px;
  }
  .commpany_in h2 span:before {
    height: 18px;
  }
  .commpany_in .txt1 {
    font-size: 21px;
    padding: 20px 0 0;
  }
  .commpany_in .txt2 {
    font-size: 16px;
    line-height: 1.8;
    padding: 5px 0 0;
  }
  .commpany_in h3 {
    font-size: 18px;
  }
}
@media screen and (max-width: 800px) {
  .commpany_in {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
  .commpany_in h2 {
    padding: 40px 0 0;
  }
  .commpany_in h2 span {
    font-size: 25px;
    padding: 0 8px;
  }
  .commpany_in h2 span:before {
    height: 15px;
  }
  .commpany_in .txt1 {
    font-size: 18px;
    padding: 20px 0 0;
  }
  .commpany_in .txt2 {
    font-size: 16px;
    line-height: 1.8;
    padding: 5px 0 0;
  }
  .commpany_in h3 {
    font-size: 18px;
  }
}

.commpany_box {
  width: 100%;
  padding: 0 0 64px;
  position: relative;
  background: #edf6ff;
}
.commpany_box ul {
  overflow: hidden;
  padding: 42px 0 0;
}
.commpany_box li {
  float: left;
  margin-right: 55px;
  background: #fff;
}
.commpany_box li:last-child {
  margin-right: 0;
}
.commpany_box li span {
  padding: 35px 0;
  display: block;
  font-size: 24px;
  color: #000;
  line-height: 1.2;
  text-align: center;
  font-weight: 400;
  letter-spacing: -0.06em;
  word-spacing: -6px;
}
.commpany_box .btn_down {
  display: block;
  text-align: center;
  margin: 33px auto 0;
  width: 390px;
  height: 53px;
  line-height: 53px;
  background: #e5f4ff;
  border: 1px solid #0094fe;
  font-size: 20px;
  font-weight: 400;
  color: #0094fe;
  border-radius: 27px 0 27px 27px;
}
@media screen and (max-width: 1279px) {
  .commpany_box li {
    width: 49%;
    margin-right: 2%;
  }
  .commpany_box li:nth-child(2) {
    margin-right: 0;
  }
  .commpany_box li:nth-child(3) {
    margin-top: 20px;
  }
  .commpany_box li img {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .commpany_box li {
    float: none;
    width: 90%;
    margin: 20px auto 0;
  }
  .commpany_box li:nth-child(2) {
    margin: 20px auto 0;
  }
  .commpany_box li:nth-child(3) {
    margin: 20px auto 0;
  }
  .commpany_box li:nth-child(1) {
    margin-top: 0;
  }
  .commpany_box .btn_down {
    width: 80%;
    height: 43px;
    line-height: 43px;
    font-size: 15px;
  }
  .commpany_box li span {
    font-size: 18px;
    padding: 20px 0;
  }
}

.commpany_box2 .bar {
  width: 1px;
  height: 50px;
  background: #0094fe;
  margin: 65px auto -5px;
}
.commpany_box2 .history {
  border-bottom: 1px solid #e3e3e3;
  padding: 59px 95px 72px;
}
.commpany_box2 .history dl:last-child {
  border: none;
}
.commpany_box2 .history dl {
  border-bottom: 1px dashed #dddddd;
  overflow: hidden;
  padding: 0 0 10px;
}
.commpany_box2 .history dl:nth-child(2) dd {
  padding-top: 73px;
}
.commpany_box2 .history dt {
  float: left;
  width: 19.4%;
  font-family: "roboto";
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  padding: 15px 0 0;
}
.commpany_box2 .history dd {
  float: left;
  width: 80.6%;
  font-weight: 300;
  font-size: 20px;
  color: #333333;
  padding: 17px 0 0 407px;
  line-height: 1.5;
  position: relative;
  letter-spacing: -0.04em;
  word-spacing: -4px;
}
.commpany_box2 .history dd strong {
  position: absolute;
  left: 0;
  top: 3px;
  line-height: 2.9;
  font-size: 20px;
  color: #0094fe;
  font-weight: 500;
}
@media screen and (max-width: 1279px) {
  .commpany_box2 .history dd {
    padding: 17px 0 0;
  }
  .commpany_box2 .history dd strong {
    position: relative;
    top: auto;
    line-height: 1.5;
    display: block;
  }
  .commpany_box2 .history dl:nth-child(2) dd {
    padding-top: 18px;
  }
}
@media screen and (max-width: 800px) {
  .commpany_box2 .bar {
    height: 30px;
    margin: 30px auto -20px;
  }
  .commpany_box2 .history {
    padding: 59px 5% 50px;
  }
  .commpany_box2 .history dt {
    font-size: 20px;
  }
  .commpany_box2 .history dd {
    font-size: 16px;
  }
  .commpany_box2 .history dd strong {
    font-size: 16px;
  }
}

.commpany_box3 {
  padding-bottom: 136px;
}
.commpany_box3 ul {
  overflow: hidden;
  margin: 68px 0 0 0;
  display: table;
  width: 100%;
}
.commpany_box3 li {
  display: table-cell;
  text-align: center;
  vertical-align: bottom;
}
.commpany_box3 li img {
  vertical-align: bottom;
}
@media screen and (max-width: 1279px) {
  .commpany_box3 {
    padding-bottom: 80px;
  }
  .commpany_box3 ul {
    text-align: center;
    display: block;
    margin: 0 0 0 0;
  }
  .commpany_box3 li {
    display: inline-block;
    margin-top: 15px;
    margin: 20px 30px 0;
  }
}

.commpany_box4 {
  width: 100%;
  padding: 0 0 0;
  position: relative;
  background: #f7faff;
}
.commpany_box4 ul {
  overflow: hidden;
  padding: 58px 0 105px;
}
.commpany_box4 li {
  float: left;
  margin-right: 38px;
  background: #fff;
  width: 290px;
  min-height: 442px;
  text-align: center;
  box-shadow: 0 3px 18px rgba(0, 0, 0, 0.14);
}
.commpany_box4 li:last-child {
  margin-right: 0;
}
.commpany_box4 li:last-child span {
  padding-top: 20px;
}
.commpany_box4 li img {
  margin-bottom: 18px;
}
.commpany_box4 li a {
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  padding: ;
  display: block;
}
.commpany_box4 li span {
  font-weight: 300;
  font-size: 15px;
  color: #333333;
  line-height: 1.46;
  display: block;
  padding: 14px 0 14px;
  letter-spacing: -0.04em;
  word-spacing: -4px;
}
@media screen and (max-width: 1279px) {
  .commpany_box4 ul {
    padding: 58px 0 50px;
  }
  .commpany_box4 li {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 20px;
    min-height: auto;
  }
  .commpany_box4 li:nth-child(2) {
    margin-right: 0;
  }
  .commpany_box4 li:nth-child(4) {
    margin-right: 0;
  }
  .commpany_box4 li img {
    width: 100%;
    margin-bottom: 13px;
  }
  .commpany_box4 li span {
    min-height: 60px;
  }
  .commpany_box4 li a {
    font-size: 18px;
  }
  .commpany_box4 li span {
    font-size: 14px;
    padding: 10px 0 10px;
  }
}
@media screen and (max-width: 800px) {
  .commpany_box4 ul {
    padding: 30px 0 40px;
  }
}

.commpany_box5 {
  width: 100%;
  padding: 0 0 38px;
  position: relative;
  background: #fff;
}
.commpany_box5 h2 {
  padding: 72px 0 80px;
  text-align: center;
}
.commpany_box5 h2 span {
  width: auto;
  display: inline-block;
  position: relative;
  color: #000;
  font-size: 36px;
  font-weight: 500;
  z-index: 1;
  padding: 0 12px;
  letter-spacing: -0.06em;
  word-spacing: -6px;
}
.commpany_box5 h2 span:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 148, 254, 0.1);
  height: 23px;
  z-index: -1;
}
.commpany_box5 h3 {
  font-size: 28px;
  color: #333;
  font-weight: 400;
  letter-spacing: -0.06em;
  line-height: 1.3;
  margin: 40px 0 0;
}
.commpany_box5 h4 {
  font-size: 22px;
  color: #000;
  font-weight: 400;
  letter-spacing: -0.06em;
  line-height: 1.3;
  margin: 0 0 6px;
}
.commpany_box5 .map_1 {
  overflow: hidden;
}
.commpany_box5 .map_1 p {
  display: inline-block;
  color: #333;
  font-size: 16px;
  letter-spacing: -0.04em;
  word-spacing: -4px;
}
.commpany_box5 .map_1 .txt1 {
  margin-right: 150px;
}
.commpany_box5 .map_1 .txt2 {
  margin-right: 136px;
}
.commpany_box5 .map_1 .txt1 span {
  display: inline-block;
  width: 54px;
  height: 54px;
  background: url("../img/contents/img_map_01.png") no-repeat 0 0;
  margin-right: 27px;
  vertical-align: middle;
  font-size: 0;
}
.commpany_box5 .map_1 .txt2 span {
  display: inline-block;
  width: 54px;
  height: 54px;
  background: url("../img/contents/img_map_02.png") no-repeat 0 0;
  margin-right: 4px;
  vertical-align: middle;
  font-size: 0;
}
.commpany_box5 .map_1 .txt3 span {
  display: inline-block;
  width: 54px;
  height: 54px;
  background: url("../img/contents/img_map_03.png") no-repeat 0 0;
  margin-right: 4px;
  vertical-align: middle;
  font-size: 0;
}
.commpany_box5 .map_2 {
  position: relative;
  padding: 30px 0 0 80px;
  background: url("../img/contents/img_map_04.png") no-repeat 0 27px;
}
.commpany_box5 .map_2 p {
  color: #333;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.04em;
  word-spacing: -4px;
  padding-top: 8px;
}
.commpany_box5 .map_2 p strong {
  font-weight: 400;
  color: #000;
}
.commpany_box5 .map_2 .num1 {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  width: 24px;
  height: 24px;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
  background: #023673;
  border-radius: 100%;
  vertical-align: 1px;
  margin-right: 1px;
}
.commpany_box5 .map_2 .num2 {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  width: 24px;
  height: 24px;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
  background: #43b1ff;
  border-radius: 100%;
  vertical-align: 1px;
  margin-right: 1px;
}
.commpany_box5 .map_3 {
  position: relative;
  padding: 38px 0 0 80px;
  background: url("../img/contents/img_map_05.png") no-repeat 0 37px;
}
.commpany_box5 .map_3 li {
  overflow: hidden;
  padding: 0 0 9px;
}
.commpany_box5 .map_3 li:before {
  content: "";
  float: left;
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background: #cecece;
  margin-right: 7px;
  margin-top: 6px;
}
.commpany_box5 .map_3 li p {
  float: left;
  width: 620px;
  color: #333;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.04em;
  word-spacing: -4px;
}
.commpany_box5 .map_3 li .bus1 {
  float: left;
  width: 183px;
  font-size: 16px;
  font-weight: 300;
}
.commpany_box5 .map_3 li .bus1 span {
  float: left;
  width: 20px;
  height: 16px;
  vertical-align: -5px;
  background: url("../img/contents/ico_bus_01.png") no-repeat 0 0;
  font-size: 0;
}
.commpany_box5 .map_3 li .bus2 {
  float: left;
  width: 242px;
  font-size: 16px;
  font-weight: 300;
}
.commpany_box5 .map_3 li .bus2 span {
  float: left;
  width: 20px;
  height: 16px;
  vertical-align: -2px;
  background: url("../img/contents/ico_bus_02.png") no-repeat 0 0;
  font-size: 0;
}
.commpany_box5 .map_3 li .bus3 {
  float: left;
  width: 57px;
  font-size: 16px;
  font-weight: 300;
}
.commpany_box5 .map_3 li .bus3 span {
  float: left;
  width: 20px;
  height: 16px;
  vertical-align: -2px;
  background: url("../img/contents/ico_bus_03.png") no-repeat 0 0;
  font-size: 0;
}
@media screen and (max-width: 1279px) {
  .commpany_box5 h2 {
    padding: 60px 0 50px;
  }
  .commpany_box5 h2 span {
    font-size: 28px;
    padding: 0 8px;
  }
  .commpany_box5 h2 span:before {
    height: 18px;
  }
  .commpany_box5 h3 {
    font-size: 22px;
  }
  .commpany_box5 h4 {
    font-size: 18px;
  }
  .commpany_box5 .map_3 li {
    padding: 0 0 15px;
  }
}
@media screen and (max-width: 800px) {
  .commpany_box5 .map_1 .txt1 span {
    margin-right: 0;
    position: absolute;
    left: 0;
    top: 10px;
    width: 45px;
    height: 45px;
    background-size: 100%;
  }
  .commpany_box5 .map_1 .txt2 span {
    margin-right: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 45px;
    height: 45px;
    background-size: 100%;
  }
  .commpany_box5 .map_1 .txt3 span {
    margin-right: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 45px;
    height: 45px;
    background-size: 100%;
  }
  .commpany_box5 .map_1 .txt1 {
    padding: 20px 0 0 60px;
    position: relative;
    margin: 0 0 20px;
    text-align: left;
  }
  .commpany_box5 .map_1 .txt2 {
    padding: 15px 0 0 60px;
    position: relative;
    margin: 0 10px 20px 0;
    text-align: left;
  }
  .commpany_box5 .map_1 .txt3 {
    padding: 15px 0 0 60px;
    position: relative;
    margin: 0 0 20px;
    text-align: left;
  }
  .commpany_box5 .map_2 {
    padding: 33px 0 0 60px;
    background-size: 45px auto;
  }
  .commpany_box5 .map_3 {
    padding: 38px 0 0 60px;
    background-size: 45px auto;
  }
  .commpany_box5 .map_3 li:before {
    display: none;
  }
  .commpany_box5 .map_3 li p:before {
    content: "";
    float: left;
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background: #cecece;
    margin-right: 7px;
    margin-top: 6px;
  }
  .commpany_box5 .map_3 li p {
    float: none;
    height: auto;
    width: 100%;
  }
  .commpany_box5 .map_3 li .bus1 {
    float: none;
    display: inline-block;
  }
  .commpany_box5 .map_3 li .bus2 {
    float: none;
    display: inline-block;
  }
  .commpany_box5 .map_3 li .bus3 {
    float: none;
    display: inline-block;
  }
  .commpany_box5 h2 {
    padding: 40px 0 30px;
  }
  .commpany_box5 h2 span {
    font-size: 25px;
    padding: 0 8px;
  }
  .commpany_box5 h2 span:before {
    height: 15px;
  }
  .commpany_box5 h3 {
    font-size: 18px;
  }
  .commpany_box5 h4 {
    font-size: 16px;
  }
}

/* modal */
div.blocker {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  padding-top: 100px;
}
div.modal {
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 95%;
  max-width: 1200px;
  padding: 65px 63px 75px;
}
div.modal a.close-modal {
  width: 35px;
  height: 34px;
  background: url("../img/common/btn_close3.png") no-repeat 50% 50%;
  right: 19px;
  top: 16px;
}
.modal .title {
  padding: 0 0 25px;
}
.modal .title span {
  width: auto;
  display: inline-block;
  position: relative;
  font-size: 36px;
  color: #000;
  font-weight: bold;
  z-index: 1;
  padding: 0 11px;
  letter-spacing: -0.06em;
}
.modal .title span:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 148, 254, 0.1);
  height: 23px;
  z-index: -1;
}
@media screen and (max-width: 1279px) {
  .blocker {
    padding-top: 100px;
  }
  div.modal {
    padding: 40px 30px 40px;
    vertical-align: top;
  }
  div.modal a.close-modal {
    right: 5px;
    top: 5px;
  }
  .modal .title {
    padding: 0 0 18px;
  }
  .modal .title span {
    font-size: 28px;
    padding: 0 8px;
  }
  .modal .title span:before {
    height: 18px;
  }
}
@media screen and (max-width: 800px) {
  .blocker {
    padding-top: 70px;
  }
  .modal .title span {
    font-size: 25px;
    padding: 0 8px;
  }
  .modal .title span:before {
    height: 15px;
  }
}

.inquiry_txt {
  position: absolute;
  right: 72px;
  top: 90px;
  text-align: right;
  color: #acacac;
  font-size: 18px;
  font-weight: 300;
}
.inquiry_box {
  width: 700px;
  margin: 0 auto;
}
.inquiry_box .line1 {
  padding-bottom: 16px;
}
.inquiry_box .line2 {
  padding-bottom: 40px;
}
.inquiry_box label {
  display: inline-block;
  width: 179px;
  font-size: 18px;
  font-weight: 400;
  color: #333;
  height: 50px;
  line-height: 50px;
  letter-spacing: -0.06em;
  vertical-align: top;
}
.inquiry_box label span {
  color: #ff0000;
}
.inquiry_box input {
  width: calc(100% - 187px);
}
.inquiry_box .data_box {
  width: calc(100% - 187px);
}
@media screen and (max-width: 800px) {
  .inquiry_box {
    width: 100%;
    margin: 0 auto;
  }
  .inquiry_box label {
    font-size: 15px;
    display: block;
    width: 100%;
  }
  .inquiry_txt {
    position: relative;
    right: auto;
    top: 0;
    margin: 0 0 15px;
    font-size: 14px;
  }
  .inquiry_box .inp_ty1 {
    width: 100%;
  }
  .inquiry_box .data_box {
    width: 100%;
  }
  .inquiry_box .line2 {
    padding-bottom: 30px;
  }
}

/* form */
input[type="text"].inp_ty1,
input[type="password"].inp_ty1 {
  display: inline-block;
  border: 1px solid #dddddd;
  line-height: 50px;
  height: 50px;
  color: #000;
  font-size: 15px;
  padding: 0 20px;
  vertical-align: middle;
  letter-spacing: -0.05em;
  border-radius: 5px;
}
input[type="text"].inp_ty2,
input[type="password"].inp_ty2 {
  display: inline-block;
  border: 1px solid #dddddd;
  line-height: 40px;
  height: 40px;
  color: #000;
  font-size: 15px;
  padding: 0 20px;
  vertical-align: middle;
  letter-spacing: -0.05em;
  border-radius: 5px;
}
textarea.data_box {
  width: 100%;
  height: 100%;
  padding: 15px;
  resize: none;
  font-size: 15px;
  color: #000;
  background: #fff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 5px;
}
.btn {
  min-width: 200px;
  height: 48px;
  line-height: 48px;
  color: #fff;
  display: inline-block;
  background: #0897fe;
  font-family: "NotoKr";
  font-size: 18px;
  font-weight: 400;
  padding: 0 10px;
  border: 0;
  vertical-align: middle;
  border-radius: 21px 0 21px 21px;
  letter-spacing: -0.05em;
}
.btn.st2 {
  background: #dddddd;
  color: #666;
}
.btn.st3 {
  background: #fff;
  color: #0897fe;
  border: 1px solid #0897fe;
}
.btn_center {
  text-align: center;
  margin-top: 47px;
}
@media screen and (max-width: 800px) {
  .btn {
    min-width: 120px;
    height: 38px;
    line-height: 38px;
    font-size: 15px;
    font-weight: 400;
    border-radius: 17px 0 17px 17px;
  }
  .btn_center {
    margin-top: 20px;
  }
}

/* notice */
.search_box {
  position: absolute;
  left: 370px;
  top: 75px;
  width: 300px;
  height: auto;
}
.search_box .inp {
  width: 300px;
  height: 40px;
  padding: 0 22px 0 15px;
  border-radius: 19px;
  background: #f5f5f5;
  border: none;
  display: block;
}
.search_box .btn_search {
  display: block;
  right: 0;
  position: absolute;
  bottom: 0;
  width: 38px;
  height: 40px;
  background: url("../img/contents/btn_search.png") no-repeat 0 48%;
  font-size: 0;
  border: none;
}
@media screen and (max-width: 1279px) {
  .search_box {
    position: relative;
    left: auto;
    top: auto;
    margin-bottom: 20px;
  }
}

.tab_link {
  position: absolute;
  right: 55px;
  top: 75px;
  width: 420px;
  height: 36px;
  text-align: right;
}
.tab_link li {
  display: inline-block;
}
.tab_link li a {
  font-size: 22px;
  color: #999;
  font-weight: 400;
  height: 36px;
  line-height: 36px;
  letter-spacing: -0.06em;
  word-spacing: -3px;
}
.tab_link li:before {
  width: 1px;
  height: 12px;
  background: #cecece;
  content: "";
  margin: 0 27px 0 22px;
  vertical-align: 2px;
  display: inline-block;
}
.tab_link li:first-child:before {
  display: none;
}
.tab_link li.on a {
  color: #0897fe;
  font-weight: 500;
  border-bottom: 3px solid #0897fe;
}

.notice_list {
  border-top: 2px solid #000;
}
.notice_list table {
  width: 100%;
}
.notice_list thead th {
  border-bottom: 1px solid #eaeaea;
  line-height: 58px;
  font-size: 16px;
  color: #000;
  font-weight: 300;
  text-align: center;
}
.notice_list tbody td {
  border-bottom: 1px solid #eaeaea;
  padding: 16px 10px 15px;
  font-size: 16px;
  color: #999999;
  font-weight: 300;
  text-align: center;
}
.notice_list tbody td.left {
  text-align: left;
  color: #000;
  font-weight: 300;
}
.notice_list tbody td.left a {
  display: block;
  color: #000;
  font-weight: 300;
}
.notice_list tbody td.left span {
  display: inline-block;
  color: #666666;
  ont-weight: 300;
  width: 210px;
  padding-left: 20px;
}
.notice_list tbody td:last-child {
  color: #666666;
}

.notice_list2 p {
  display: table;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  position: relative;
}
.notice_list2 p > span {
  display: table-cell;
  font-size: 16px;
  color: #999999;
  font-weight: 300;
  text-align: center;
  padding: 16px 10px 15px;
  box-sizing: border-box;
}
.notice_list2 p .t1 {
  width: 95px;
}
.notice_list2 p .t2 {
  width: auto;
  text-align: left;
  color: #000;
  font-weight: 300;
}
.notice_list2 p .t2 a {
  display: block;
  color: #000;
  font-weight: 300;
}
.notice_list2 p .t2 span {
  color: #000;
  font-weight: 300;
}
.notice_list2 p .t3 {
  width: 190px;
}
.notice_list2 p .t4 {
  width: 120px;
}
.notice_list2 p .t5 {
  width: 100px;
}

@media screen and (max-width: 1279px) {
  .tab_link {
    top: 94px;
  }
  .notice_list {
    margin-top: 20px;
  }
  .tab_link {
    position: relative;
    right: auto;
    top: auto;
    text-align: left;
  }
  .tab_link li a {
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    line-height: 30px;
  }
  .tab_link li:before {
    margin: 0 18px 0 14px;
  }
}
@media screen and (max-width: 900px) {
  .notice_list tbody td.left span {
    display: block;
    padding: 0 0 3px;
  }
  .notice_list thead th {
    font-size: 15px;
  }
  .notice_list tbody td {
    font-size: 15px;
  }

  .notice_list {
    display: none;
  }
  .notice_list2 {
    border-top: 2px solid #000;
  }
  .notice_list2 p .t1 {
    display: none;
  }
  .notice_list2 p .t2 {
    display: block;
    width: 100%;
    font-weight: 500;
  }
  .notice_list2 p .t2 a {
    font-weight: 400;
  }
  .notice_list2 p .t2 span {
    color: #999;
    display: block;
    margin-bottom: 7px;
    font-weight: 300;
  }
  .notice_list2 p .t3 {
    width: 200px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;
    display: block;
    padding-right: 0;
    font-size: 14px;
  }
  .notice_list2 p .t4 {
    display: none;
  }
  .notice_list2 p .t5 {
    display: none;
  }
}

.paging {
  height: 18px;
  margin: 35px 0 0;
  font-size: 12px;
  text-align: center;
}
.paging a {
  z-index: 2;
  display: inline-block;
  min-width: 27px;
  height: 18px;
  padding: 0 6px;
  line-height: 20px;
  color: #000;
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 300;
  margin: 0 4px;
}
.paging a:hover,
.paging a:focus,
.paging strong {
  position: relative;
  font-weight: 500;
  text-decoration: none;
  color: #0897fe;
  display: inline-block;
  min-width: 27px;
  height: 18px;
  padding: 0 6px;
  line-height: 18px;
  font-size: 17px;
  vertical-align: middle;
}
.paging strong:before {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #e6f4ff;
  height: 10px;
  content: "";
  display: block;
  z-index: -1;
}
.paging .first,
.paging .first:hover,
.paging .first:focus {
  width: 21px;
  min-width: 21px;
  height: 18px;
  background: url("../img/common/btn_page_first.png") 0 0 no-repeat;
  font-size: 0;
  padding: 0;
  margin: 0 10px;
}
.paging .prev,
.paging .prev:hover,
.paging .prev:focus {
  width: 11px;
  min-width: 11px;
  height: 18px;
  background: url("../img/common/btn_page_prev.png") 0 0 no-repeat;
  font-size: 0;
  padding: 0;
  margin: 0 35px 0 10px;
}
.paging .next,
.paging .next:hover,
.paging .next:focus {
  width: 11px;
  min-width: 21px;
  height: 18px;
  background: url("../img/common/btn_page_next.png") 0 0 no-repeat;
  font-size: 0;
  padding: 0;
  margin: 0 10px 0 35px;
}
.paging .last,
.paging .last:hover,
.paging .last:focus {
  width: 21px;
  min-width: 11px;
  height: 18px;
  background: url("../img/common/btn_page_last.png") 0 0 no-repeat;
  font-size: 0;
  padding: 0;
  margin: 0 10px;
}
.paging .first:hover,
.paging .prev:hover,
.paging .next:hover,
.paging .last:hover,
.paging .first:focus,
.paging .prev:focus,
.paging .next:focus,
.paging .last:focus {
}
@media screen and (max-width: 900px) {
  .paging a {
    font-size: 15px;
    margin: 0 2px;
  }
  .paging .first,
  .paging .first:hover,
  .paging .first:focus {
    margin: 0 5px;
  }
  .paging .prev,
  .paging .prev:hover,
  .paging .prev:focus {
    margin: 0 15px 0 5px;
  }
  .paging .next,
  .paging .next:hover,
  .paging .next:focus {
    margin: 0 5px 0 15px;
  }
  .paging .last,
  .paging .last:hover,
  .paging .last:focus {
    margin: 0 5px;
  }
}

.notice_view {
  position: relative;
  border-top: 2px solid #000;
}
.notice_view h2 {
  padding: 16px 35px;
  border-bottom: 1px solid #eaeaea;
  font-size: 18px;
  color: #000;
  font-weight: 300;
  padding-right: 110px;
  word-spacing: -4px;
  letter-spacing: -0.06em;
}
.notice_view .date {
  position: absolute;
  right: 24px;
  top: 19px;
  font-size: 16px;
  color: #666;
  text-align: right;
  width: 100px;
}
.notice_view .data {
  padding: 35px;
  border-bottom: 1px solid #eaeaea;
  min-height: 200px;
  font-size: 16px;
  color: #000000;
  line-height: 1.8;
  font-weight: 300;
}
.notice_view .data p {
  font-size: 16px;
  color: #000000;
  line-height: 1.8;
  font-weight: 300;
}

.notice_view2 {
  border-top: 2px solid #000;
  position: relative;
}
.notice_view2 dl {
  width: 100%;
  display: table;
}
.notice_view2 dt {
  display: table-cell;
  width: 160px;
  border-bottom: 1px solid #eaeaea;
  border-top: none;
  font-size: 18px;
  color: #000;
  padding: 17px 0 17px 32px;
  text-align: left;
  width: 178px;
  background: #fff;
  font-weight: normal;
  vertical-align: middle;
  background: #f8f8f8;
}
.notice_view2 dd {
  display: table-cell;
  width: calc(100% - 160px);
  border-bottom: 1px solid #eaeaea;
  border-top: none;
  font-size: 18px;
  color: #000;
  padding: 6px 6px;
  line-height: 40px;
  padding-left: 20px;
}

@media screen and (max-width: 900px) {
  .notice_view h2 {
    padding: 10px 10px;
    border-bottom: none;
  }
  .notice_view .date {
    position: relative;
    right: auto;
    top: auto;
    font-size: 15px;
    color: #666;
    text-align: left;
    width: 100%;
    padding: 0 10px 10px;
    border-bottom: 1px solid #eaeaea;
  }
  .notice_view .data {
    padding: 10px;
    font-size: 15px;
  }

  .notice_view2 dt {
    display: none;
  }
  .notice_view2 .t1 {
    display: none;
  }
  .notice_view2 .t2 dd {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    font-size: 14px;
    color: #999;
    text-align: right;
  }
  .notice_view2 .t3 dd span {
    display: block;
    color: #999;
  }
  .notice_view2 .t3 dd {
    padding: 10px 0;
    font-weight: 400;
    color: #333;
    line-height: 1.5;
  }
  .notice_view2 .t5 dt {
    display: table-cell;
    background: #fff;
    width: 108px;
    padding: 10px 10px 10px 5px;
  }
  .notice_view2 .t5 dt:after {
    content: "";
    margin-left: 15px;
    display: inline-block;
    width: 1px;
    height: 22px;
    vertical-align: middle;
    background: #eeeeee;
  }
  .notice_view2 .t5 dd {
    padding-left: 0;
  }
  .notice_view2 .t4 dd {
    padding-left: 0;
  }
}

.file_box {
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  vertical-align: middle;
  padding-right: 85px;
}
.file_box .inp_ty1 {
  width: 100%;
}
.file_box .file_btn {
  position: absolute;
  top: 3px;
  right: 1px;
  display: block;
  width: 74px;
  height: 34px;
  background: #dddddd;
  vertical-align: middle;
  border-radius: 2px;
}
.file_box .file_btn:after {
  content: "파일첨부";
  display: block;
  width: 74px;
  height: 34px;
  line-height: 34px;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  color: #333333;
  font-weight: 300;
  font-size: 14px;
}
.file_box .file_btn input {
  width: 85px;
  height: 34px;
  cursor: pointer;
  opacity: 0;
  vertical-align: top;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.notice_write {
  border-top: 2px solid #000;
}
.notice_write table {
  width: 100%;
}
.notice_write th {
  width: 160px;
  border-bottom: 1px solid #eaeaea;
  border-top: none;
  font-size: 18px;
  color: #000;
  padding: 17px 0 17px 32px;
  text-align: left;
  width: 178px;
  background: #fff;
  font-weight: normal;
  vertical-align: middle;
}
.notice_write td {
  width: calc(100% - 160px);
  border-bottom: 1px solid #eaeaea;
  border-top: none;
  font-size: 18px;
  color: #000;
  padding: 6px 6px;
  line-height: 40px;
}

.notice_write2 {
  border-top: 2px solid #000;
}
.notice_write2 dl {
  display: table;
  width: 100%;
}
.notice_write2 dt {
  display: table-cell;
  width: 160px;
  border-bottom: 1px solid #eaeaea;
  border-top: none;
  font-size: 18px;
  color: #000;
  padding: 17px 0 17px 32px;
  text-align: left;
  width: 178px;
  background: #fff;
  font-weight: normal;
  vertical-align: middle;
}
.notice_write2 dd {
  display: table-cell;
  vertical-align: middle;
  width: calc(100% - 160px);
  border-bottom: 1px solid #eaeaea;
  border-top: none;
  font-size: 18px;
  color: #000;
  padding: 6px 6px;
  line-height: 40px;
}

.link1 {
  color: #0897fe;
  font-weight: 400;
}

@media screen and (max-width: 900px) {
  .notice_write th {
    font-size: 16px;
    padding: 17px 0 17px 20px;
    width: 100px;
  }
  .notice_write td {
    font-size: 16px;
    width: calc(100% - 100px);
  }

  .notice_write2 dt {
    display: block;
    width: 100%;
    border: none;
    padding-left: 0;
    padding-bottom: 0;
  }
  .notice_write2 dd {
    display: block;
    width: 100%;
    border: none;
    padding-left: 0;
    padding-top: 0;
  }
  .notice_write2 .t dt {
    display: table-cell;
    width: 80px;
    border-bottom: 1px solid #eaeaea;
    padding: 6px 0 4px 0;
  }
  .notice_write2 .t dd {
    display: table-cell;
    width: calc(100% - 80px);
    vertical-align: middle;
    border-bottom: 1px solid #eaeaea;
    padding: 6px 6px 6px 0;
  }
  .notice_write2 textarea {
    margin: 0;
    vertical-align: top;
  }
}

.check_ty1 {
  position: relative;
  display: inline-block;
  height: 40px;
  width: auto;
  margin: 0;
  vertical-align: middle;
  margin-right: 50px;
}
.check_ty1 span {
  position: relative;
  display: block;
  height: 40px;
  font-size: 18px;
  color: #000;
  font-weight: 300;
  line-height: 40px;
  padding-left: 33px;
  cursor: pointer;
}
.check_ty1 span:before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  background: url("../img/common/bg_check.png") no-repeat 0 50%;
  border-radius: 3px;
  left: 0;
  top: 6px;
}
.check_ty1 input[type="radio"],
.check_ty1 input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
  margin-left: -20px;
  position: absolute;
}
.check_ty1 input[type="radio"]:checked + span:before,
.check_ty1 input[type="checkbox"]:checked + span:before {
  background: url("../img/common/bg_check_on.png") no-repeat 0 50%;
  z-index: 2;
}
@media screen and (max-width: 900px) {
  .check_ty1 {
    margin-right: 20px;
  }
  .check_ty1 span {
    font-size: 16px;
  }
}

/* login_box */
.login_box {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  min-height: 563px;
}
.login_box legend {
  overflow: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
  height: 0;
  font-size: 0;
  line-height: 0;
  padding: 0;
}
.login_box h2 {
  padding: 72px 0 0;
  text-align: center;
  margin: 30px 0 60px;
}
.login_box h2 span {
  width: auto;
  display: inline-block;
  position: relative;
  color: #000;
  font-size: 36px;
  font-weight: 500;
  z-index: 1;
  padding: 0 12px;
  letter-spacing: -0.06em;
  word-spacing: -6px;
}
.login_box h2 span:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 148, 254, 0.1);
  height: 23px;
  z-index: -1;
}
.login_box .login_in {
  width: 490px;
  padding: 47px 40px 25px 39px;
  margin: 0 auto;
  border: 1px solid #d6d6d6;
  background: #fff;
}
.login_box .txt {
  border: 1px solid #dddddd;
  height: 47px;
  line-height: 45px;
  padding: 0 0 0 15px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
}
.login_box .login {
  margin-top: 9px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #0897fe;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border: 0;
  border-radius: 21px 0 21px 21px;
}

.notice_box {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  padding: 0 0 80px;
}
.notice_box h2 {
  padding: 72px 0 0;
  text-align: center;
  margin: 30px 0 50px;
}
.notice_box h2 span {
  width: auto;
  display: inline-block;
  position: relative;
  color: #000;
  font-size: 36px;
  font-weight: 500;
  z-index: 1;
  padding: 0 12px;
  letter-spacing: -0.06em;
  word-spacing: -6px;
}
.notice_box h2 span:before {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0, 148, 254, 0.1);
  height: 23px;
  z-index: -1;
}

.select1 {
  width: auto;
  height: 40px;
  border: 1px solid #d8d8d8;
  background: #fff url("../img/common/arrow_select_1.png") no-repeat 100% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 12px;
  color: #333;
  font-size: 13px;
  vertical-align: top;
  border-radius: 5px;
}
.select1::-ms-expand {
  display: none;
}
.select1 option {
  padding-left: 12px;
}
.notice_box .search_box {
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  height: auto;
  text-align: right;
  margin: 0 0 20px;
}
.notice_box .search_box .inp {
  display: inline-block;
}
.notice_write.st2 th {
  background: #f8f8f8;
}
.notice_write.st2 td {
  padding-left: 20px;
}
@media screen and (max-width: 1280px) {
  .login_box {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  .notice_box {
    width: 100%;
    padding: 0 30px 80px;
  }
  .notice_box h2 {
    padding: 30px 0 0;
  }
  .notice_box h2 span {
    font-size: 25px;
    padding: 0 8px;
  }
}

@media screen and (max-width: 500px) {
  .notice_box .search_box .inp {
    width: 100%;
    margin-top: 10px;
  }
  .login_box .login_in {
    width: 100%;
  }
  .login_box h2 {
    padding: 30px 0 0;
  }
  .login_box h2 span {
    font-size: 25px;
    padding: 0 8px;
  }
}
