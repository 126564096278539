@charset "utf-8";

/* NotoSans */
@font-face {
  font-family: 'NotoKr';
  font-weight: 100;
  src: url('../fonts/NotoSansCJKkr-Light.eot');
  src: url('../fonts/NotoSansCJKkr-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/NotoSansCJKkr-Light.woff') format('woff');
}

@font-face {
  font-family: 'NotoKr';
  font-weight: 300;
  src: url('../fonts/NotoSansCJKkr-Regular.eot');
  src: url('../fonts/NotoSansCJKkr-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/NotoSansCJKkr-Regular.woff') format('woff');
}

@font-face {
  font-family: 'NotoKr';
  font-weight: 400;
  src: url('../fonts/NotoSansCJKkr-Medium.eot');
  src: url('../fonts/NotoSansCJKkr-Medium.eot?#iefix') format('embedded-opentype'),
       url('../fonts/NotoSansCJKkr-Medium.woff') format('woff');
}

@font-face {
  font-family: 'NotoKr';
  font-weight: 500;
  src: url('../fonts/NotoSansCJKkr-Bold.eot');
  src: url('../fonts/NotoSansCJKkr-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/NotoSansCJKkr-Bold.woff') format('woff');
}

/* roboto */
@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-weight: normal;
  src: format('embedded-opentype'),
       url('../fonts/roboto-light.woff') format('woff');
}

@font-face {
  font-family: 'roboto';
  font-style: bold;
  font-weight: bold;
  src: format('embedded-opentype'),
       url('../fonts/roboto-bold.woff') format('woff');
}

/* 웹폰트(나눔고딕) */
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/NanumGothic-Regular.eot');
  src: url('../fonts/NanumGothic-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/NanumGothic-Regular.woff2') format('woff2'),
       url('../fonts/NanumGothic-Regular.woff') format('woff'),
       url('../fonts/NanumGothic-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: bold;
  font-weight: bold;
  src: url('../fonts/NanumGothic-Bold.eot');
  src: url('../fonts/NanumGothic-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/NanumGothic-Bold.woff2') format('woff2'),
       url('../fonts/NanumGothic-Bold.woff') format('woff'),
       url('../fonts/NanumGothic-Bold.ttf') format('truetype');
}
/* 
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td:not(.text_edit),
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	letter-spacing: -0.035em;
	font-family:'NotoKr',sans-serif;
	font-weight:100;
	color: #666;
} */

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea {-webkit-appearance:none; -webkit-border-radius:0; border-radius:0; appearance:none;}


input[type="submit"] {cursor: pointer;}


/* IE10 이상에서 input box 에 추가된 지우기 버튼 제거 */
input::-ms-clear { display: none; }

/* input type number 에서 화살표 제거 */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.3;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


*{padding:0;margin:0;box-sizing:border-box;word-break: keep-all;}
body{}
a{text-decoration:none;}
button{cursor:pointer;outline:none;}
li{list-style:none;}


@media (max-width:1023px){
	body, div, p {font-size: 16px;}
}