
.text_edit{
  font: unset;
  font-weight: unset;
  color: black;
}
.ck-editor__main, .ck-editor__editable_inline{
  font: unset;
}
.ck-editor__editable_inline strong{
  font: unset;
  font-weight: 400;
  color: black;
}
.ck-editor__editable_inline i{
  font-style: italic;
  font-weight: unset;
  color: black;
}
.ck-editor__p, .ck-editor__editable_inline p{
  font: unset;
  font-weight: unset;
  color: black;
}
.ck-editor__editable_inline ol{
  all: unset;
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  font-weight: unset;
  color: black;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.ck-editor__editable_inline li{
  all: unset;
  display: list-item;
  text-align: -webkit-match-parent;
  margin-bottom: 0.5rem;
  font-weight: unset;
  color: black;
}
.ck-editor__editable_inline ul{
  all: unset;
  display: block;
  list-style-type: disc;
  font-weight: unset;
  color: black;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.ck-editor__editable_inline table{
  all: unset;
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  font-weight: unset;
  color: black;
}
.ck-editor__editable_inline table *{
  color: black;
}
.edit_view blockquote{
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.ck-editor__editable {
  min-height: 400px;
}