@font-face {
  font-family: "NotoKr";
  font-weight: 100;
  src: url("./NotoSansCJKkr-Light.eot");
  src: url("./NotoSansCJKkr-Light.eot?#iefix") format("embedded-opentype"),
    url("./NotoSansCJKkr-Light.woff") format("woff");
}

@font-face {
  font-family: "NotoKr";
  font-weight: 300;
  src: url("./NotoSansCJKkr-Regular.eot");
  src: url("./NotoSansCJKkr-Regular.eot?#iefix") format("embedded-opentype"),
    url("./NotoSansCJKkr-Regular.woff") format("woff");
}

@font-face {
  font-family: "NotoKr";
  font-weight: 400;
  src: url("./NotoSansCJKkr-Medium.eot");
  src: url("./NotoSansCJKkr-Medium.eot?#iefix") format("embedded-opentype"),
    url("./NotoSansCJKkr-Medium.woff") format("woff");
}

@font-face {
  font-family: "NotoKr";
  font-weight: 500;
  src: url("./NotoSansCJKkr-Bold.eot");
  src: url("./NotoSansCJKkr-Bold.eot?#iefix") format("embedded-opentype"),
    url("./NotoSansCJKkr-Bold.woff") format("woff");
}

/* roboto */
@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: normal;
  src: format("embedded-opentype"), url("./roboto-light.woff") format("woff");
}

@font-face {
  font-family: "roboto";
  font-style: bold;
  font-weight: bold;
  src: format("embedded-opentype"), url("./roboto-bold.woff") format("woff");
}

/* 웹폰트(나눔고딕) */
@font-face {
  font-family: "Nanum Gothic";
  font-style: normal;
  font-weight: normal;
  src: url("./NanumGothic-Regular.eot");
  src: url("./NanumGothic-Regular.eot?#iefix") format("embedded-opentype"),
    url("./NanumGothic-Regular.woff2") format("woff2"),
    url("./NanumGothic-Regular.woff") format("woff"),
    url("./NanumGothic-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Nanum Gothic";
  font-style: bold;
  font-weight: bold;
  src: url("./NanumGothic-Bold.eot");
  src: url("./NanumGothic-Bold.eot?#iefix") format("embedded-opentype"),
    url("./NanumGothic-Bold.woff2") format("woff2"),
    url("./NanumGothic-Bold.woff") format("woff"),
    url("./NanumGothic-Bold.ttf") format("truetype");
}
